import React from "react";
import { Link } from "gatsby";
import darkLogo from "../../assets/images/logos/default.svg";
import darkLogoStacked from '../../assets/images/logos/stacked.svg';
import lightLogo from "../../assets/images/logos/default-white.svg";
import lightLogoStacked from '../../assets/images/logos/stacked-white.svg';

const Logo = ({ color = "dark", height, className = "", ...rest }) => {
  const logoSrc = (color === 'dark') ? darkLogo : lightLogo
  const logoStackedSrc = (color === 'dark') ? darkLogoStacked : lightLogoStacked

  return (
    <Link to="/" className={`${className}`} {...rest}>
      <img className="d-none d-sm-flex" src={logoSrc} alt="Propellant Software logo" width="180px"/>
      <img className="d-sm-none" src={logoStackedSrc} alt="Propellant Software logo" width="95px"/>
    </Link>
  );
};

export default Logo;
