import React, { useState } from "react";

import { Form } from "react-bootstrap";

import { Input, TextArea, Text, Button } from "../Core";

const ContactForm = ({ theme = "dark", ...rest }) => {
  const [status, setStatus] = useState("")

  const handleSubmit = (evt) => {
    evt.preventDefault();
    setStatus("SENDING")
    const form = evt.target;
    const data = new FormData(form);
    const xhr = new XMLHttpRequest();
    xhr.open(form.method, form.action);
    xhr.setRequestHeader("Accept", "application/json");
    xhr.onreadystatechange = () => {
      if (xhr.readyState !== XMLHttpRequest.DONE) return;
      if (xhr.status === 200) {
        form.reset();
        setStatus("SUCCESS")
      } else {
        setStatus("ERROR")
      }
    };
    xhr.send(data);
  }

  let buttonText = 'SEND';
  const isDisabled = (status === "SENDING" || status === "SUCCESS");

  if (status === "SENDING") {
    buttonText = "SENDING...";
  } else if (status === "SUCCESS") {
    buttonText = "THANKS FOR THE MESSAGE!";
  }

  return (
    <Form
      name="contact"
      method="post"
      data-netlify="true"
      data-netlify-honeypot="bot-field"
      {...rest}
      action="https://formspree.io/f/xjvppwvd"
      onSubmit={handleSubmit}
    >
      {/* You still need to add the hidden input with the form name to your JSX form */}
      <input type="hidden" name="form-name" value="contact" />
      <div className="mt-4">
        <Input type="text" placeholder="Your name" required name="name" />
      </div>
      <div className="mt-4">
        <Input type="email" placeholder="Email address" required name="email" />
      </div>
      <div className="mt-4">
        <Input type="text" placeholder="Subject" required name="subject" />
      </div>
      <div className="mt-4 ">
        <TextArea
          rows={4}
          placeholder="Write your message"
          required
          name="message"
        />
      </div>
      <div className="mt-4 mt-lg-5">
        <Button arrowRight variant="primary" type="submit" disabled={isDisabled}>
          {buttonText}
        </Button>
      </div>
      {status === 'ERROR' ? (
        <Text mt={3} color="#FF4733">
          Sorry we couldn't get that message, please try again or send us an email separately.
        </Text>
      ) : null}
    </Form>
  );
};

export default ContactForm;
